import React, { useEffect, useState } from "react";
import Feed from "../Feed/Feed";
import PostForm from "../PostForm/PostForm";
import PostsHeader from "../PostsHeader/PostsHeader";
import styles from "./Posts.module.css";

const Posts = () => {
  const [posts, setPosts] = useState([]);

  const getPosts = async () => {
    const resp = await fetch(
      `https://my-worker.michaelhall923.workers.dev/posts?_=${Date.now()}`
    );
    const postsResp = await resp.json();
    setPosts(postsResp.posts);
  };

  useEffect(() => {
    getPosts();
  }, []);

  return (
    <div className={styles.posts}>
      <PostsHeader title="Home" />
      <PostForm getPosts={getPosts} />
      <Feed posts={posts} getPosts={getPosts} />
    </div>
  );
};

export default Posts;
