import React from "react";
import Post from "../Post/Post";
import styles from "./Feed.module.scss";

const Feed = ({ posts, getPosts }) => {
  return (
    <div className={styles.feed}>
      {posts.map((post) => (
        <Post key={post.id} post={post} getPosts={getPosts} />
      ))}
    </div>
  );
};

export default Feed;
