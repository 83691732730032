import React, { useState } from "react";
import styles from "./CommentForm.module.scss";
const cn = require("classnames");

const CommentForm = ({ postId, getComments }) => {
  const [username, setUsername] = useState("");
  const [content, setContent] = useState("");
  const [isPosting, setIsPosting] = useState(false);

  const canPost = () => {
    return username && content;
  };

  function handleSubmit(e) {
    e.preventDefault();
    setIsPosting(true);
    let comment = {
      postId,
      username,
      content,
    };
    // console.log(JSON.stringify(comment));
    fetch("https://my-worker.michaelhall923.workers.dev/comments", {
      method: "POST",
      body: JSON.stringify(comment),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((res) => {
        // console.log(res);
        resetForm();
        getComments();
      })
      .catch((err) => {
        // console.log(err);
      })
      .finally(() => {
        setIsPosting(false);
      });
  }

  function resetForm() {
    setUsername("");
    setContent("");
  }

  return (
    <div className={styles.commentFormContainer}>
      <form onSubmit={handleSubmit}>
        <div className={styles.commentFields}>
          <input
            type="text"
            placeholder="Author"
            name="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            autoComplete="off"
          />
          <textarea
            className={styles.contentField}
            placeholder="Comment"
            name="content"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            maxLength={140}
            autoComplete="off"
          />
        </div>
        <div className={styles.commentSubmit}>
          <button
            className={cn({
              [styles.commentButton]: true,
            })}
            type="submit"
            disabled={!canPost() || isPosting}
          >
            Post
          </button>
        </div>
      </form>
    </div>
  );
};

export default CommentForm;
