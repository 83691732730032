import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { injectLinks } from "../../lib/utils";
import CommentForm from "../CommentForm/CommentForm";
import Comments from "../Comments/Comments";
import DeletePost from "../Post/DeletePost/DeletePost";
import LikePost from "../Post/LikePost/LikePost";
import PostImage from "../PostImage/PostImage";
import PostsHeader from "../PostsHeader/PostsHeader";
import ReplyPost from "../ReplyPost/ReplyPost";
import styles from "./SinglePost.module.scss";

const SinglePost = ({ id }) => {
  const [post, setPost] = useState(false);
  const [comments, setComments] = useState([]);

  const getPost = async () => {
    const resp = await fetch(
      `https://my-worker.michaelhall923.workers.dev/posts/${id}`
    );
    const postResp = await resp.json();
    setPost(postResp);
  };

  const getComments = async () => {
    const resp = await fetch(
      `https://my-worker.michaelhall923.workers.dev/posts/${id}/comments?_=${Date.now()}`
    );
    const commentsResp = await resp.json();
    setComments(commentsResp);
  };

  useEffect(() => {
    getPost();
    getComments();
  }, []);

  return (
    <div className={styles.posts}>
      <Helmet>
        <title>{(post.title ? post.title : "") + " | Twitter"}</title>
      </Helmet>
      <PostsHeader title="Post" backUrl="/" />
      {post && (
        <div className={styles.post}>
          <div className={styles.title}>
            <h2>
              {post.title}{" "}
              <span className={styles.author}>@{post.username}</span>
            </h2>
            <div className={styles.postControls}>
              {/* <ReplyPost id={post.id} /> */}
              <LikePost post={post} />
              <DeletePost id={post.id} getPosts={getPost} backUrl="/" />
            </div>
          </div>
          <div className={styles.content}>
            <p
              dangerouslySetInnerHTML={{
                __html: injectLinks(post?.content ?? ""),
              }}
            ></p>
          </div>
          {post.imgUrl && <PostImage url={post.imgUrl} alt={post.title} />}
        </div>
      )}
      <CommentForm postId={id} getComments={getComments} />
      {comments && <Comments comments={comments} postId={id} />}
    </div>
  );
};

export default SinglePost;
