import React from "react";
import styles from "./PostsHeader.module.scss";
import { BiArrowBack } from "react-icons/bi";
import { Link } from "@reach/router";

const PostsHeader = ({ title, backUrl }) => {
  return (
    <div className={styles.postsHeader}>
      {backUrl && (
        <Link to="/" className={styles.back}>
          <BiArrowBack />
        </Link>
      )}
      {title}
    </div>
  );
};

export default PostsHeader;
