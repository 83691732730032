import { Router } from "@reach/router";

import Posts from "./components/Posts/Posts";
import Layout from "./components/Layout/Layout";
import SinglePost from "./components/SinglePost/SinglePost";
import { Helmet } from "react-helmet";

function App() {
  return (
    <>
      <Helmet>
        <title>Twitter. It's what's happening.</title>
      </Helmet>
      <Router>
        <Layout path="/">
          <Posts path="/" />
          <SinglePost path="/posts/:id" />
        </Layout>
      </Router>
    </>
  );
}

export default App;
