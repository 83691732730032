import React, { useState } from "react";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import styles from "./LikePost.module.scss";
const cn = require("classnames");

const LikePost = ({ post }) => {
  const [liked, setLiked] = useState(!!post.liked);

  function likePost(e) {
    e.stopPropagation();
    setLiked(!liked);
    fetch(`https://my-worker.michaelhall923.workers.dev/posts/${post.id}`, {
      method: "PUT",
      body: JSON.stringify({ liked: !liked }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div
      className={cn({
        [styles.likePost]: true,
        [styles.liked]: liked,
      })}
      onClick={likePost}
    >
      {liked ? <FaHeart /> : <FaRegHeart />}
    </div>
  );
};

export default LikePost;
