import React, { useEffect, useState } from "react";
import Comment from "../Comment/Comment";
import styles from "./Comments.module.scss";

const Comments = ({ comments }) => {
  return (
    <div className={styles.comments}>
      {comments &&
        comments.map((comment) => (
          <Comment key={comment.id} comment={comment} />
        ))}
    </div>
  );
};

export default Comments;
