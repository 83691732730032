import React from "react";
import styles from "./PostImage.module.scss";

const PostImage = ({ url, alt }) => {
  return (
    <div className={styles.imageContainer}>
      <img src={url} alt={alt} />
    </div>
  );
};

export default PostImage;
