import React from "react";
import { FaReply } from "react-icons/fa";
import styles from "./ReplyPost.module.scss";

const ReplyPost = ({ id }) => {
  function replyPost(e) {
    e.stopPropagation();
    window.location.href = `/posts/${id}`;
  }

  return (
    <div className={styles.replyPost}>
      <FaReply onClick={replyPost} />
    </div>
  );
};

export default ReplyPost;
