import React from "react";
import PostImage from "../PostImage/PostImage";
import styles from "./Post.module.scss";
import DeletePost from "./DeletePost/DeletePost";
import LikePost from "./LikePost/LikePost";
import ReplyPost from "../ReplyPost/ReplyPost";
import { injectLinks } from "../../lib/utils";
import { navigate } from "@reach/router";

const Post = ({ post, getPosts }) => {
  function goToPost() {
    navigate(`/posts/${post.id}`);
  }

  return (
    <div className={styles.post} onClick={goToPost}>
      <div className={styles.title}>
        <h2>
          {post.title} <span className={styles.author}>@{post.username}</span>
        </h2>
        <div className={styles.postControls}>
          <ReplyPost id={post.id} />
          <LikePost post={post} getPosts={getPosts} />
          <DeletePost id={post.id} getPosts={getPosts} />
        </div>
      </div>
      <div className={styles.content}>
        <p dangerouslySetInnerHTML={{ __html: injectLinks(post.content) }}></p>
      </div>
      {post.imgUrl && <PostImage url={post.imgUrl} alt={post.title} />}
    </div>
  );
};

export default Post;
