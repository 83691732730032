import React, { useState } from "react";
import styles from "./PostForm.module.scss";
const cn = require("classnames");

const PostForm = ({ getPosts }) => {
  const [title, setTitle] = useState("");
  const [username, setUsername] = useState("");
  const [content, setContent] = useState("");
  const [imgUrl, setImgUrl] = useState("");
  const [isPosting, setIsPosting] = useState(false);

  const canPost = () => {
    return title && username && content;
  };

  function handleSubmit(e) {
    e.preventDefault();
    setIsPosting(true);
    let post = {
      title,
      username,
      content,
      imgUrl,
    };
    console.log(JSON.stringify(post));
    fetch("https://my-worker.michaelhall923.workers.dev/posts", {
      method: "POST",
      body: JSON.stringify(post),
      credentials: "include",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((res) => {
        // console.log(res);
        resetForm();
        getPosts();
        // document.cookie = res.headers.get("Set-Cookie");
        console.log(res.headers.get("Set-Cookie"));
      })
      .catch((err) => {
        // console.log(err);
      })
      .finally(() => {
        setIsPosting(false);
      });
  }

  function resetForm() {
    setTitle("");
    setUsername("");
    setContent("");
    setImgUrl("");
  }

  return (
    <div className={styles.postFormContainer}>
      <form onSubmit={handleSubmit}>
        <div className={styles.postFields}>
          <input
            type="text"
            placeholder="Title"
            name="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            autoComplete="off"
          />
          <input
            type="text"
            placeholder="Author"
            name="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            autoComplete="off"
          />
          <input
            type="text"
            placeholder="Image URL (Optional)"
            name="imgUrl"
            value={imgUrl}
            onChange={(e) => setImgUrl(e.target.value)}
            autoComplete="off"
          />
          <textarea
            className={styles.contentField}
            placeholder="What's happening?"
            name="content"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            maxLength={140}
            autoComplete="off"
          />
        </div>
        <div className={styles.postSubmit}>
          <button
            className={cn({
              [styles.postButton]: true,
            })}
            type="submit"
            disabled={!canPost() || isPosting}
          >
            Post
          </button>
        </div>
      </form>
    </div>
  );
};

export default PostForm;
