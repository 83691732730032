import { navigate } from "@reach/router";
import React from "react";
import { FaRegTrashAlt } from "react-icons/fa";
import styles from "./DeletePost.module.scss";

const DeletePost = ({ id, getPosts, backUrl }) => {
  function deletePost(e) {
    e.stopPropagation();
    fetch(`https://my-worker.michaelhall923.workers.dev/posts/${id}`, {
      method: "DELETE",
    })
      .then((res) => {
        // console.log(res);
        if (backUrl) navigate(backUrl);
        getPosts();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div className={styles.deletePost}>
      <FaRegTrashAlt onClick={deletePost} />
    </div>
  );
};

export default DeletePost;
