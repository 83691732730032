export function injectLinks(content) {
  return content.replace(
    /(<a href=")?((https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)))(">(.*)<\/a>)?/gi,
    function () {
      return (
        '<a href="' +
        arguments[2] +
        '" target="_blank">' +
        (arguments[7] || arguments[2]) +
        "</a>"
      );
    }
  );
}
