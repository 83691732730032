import React from "react";
import styles from "./Comment.module.scss";
import { injectLinks } from "../../lib/utils";

const Comment = ({ comment }) => {
  return (
    <div className={styles.comment}>
      <div className={styles.title}>
        <h2>@{comment.username}</h2>
        {/* <div className={styles.postControls}>
          <ReplyPost id={post.id} />
          <LikePost post={post} getPosts={getPosts} />
          <DeletePost id={post.id} getPosts={getPosts} />
        </div> */}
      </div>
      <div className={styles.content}>
        <p
          dangerouslySetInnerHTML={{ __html: injectLinks(comment.content) }}
        ></p>
      </div>
    </div>
  );
};

export default Comment;
